import React from "react"
import { Link } from "gatsby"

import FPLayout from "../components/fplayout"
import SEO from "../components/seo"
import SlideShow from '../components/SlideShow'
import { Container, Row, Col, Button } from "reactstrap"

import Image from "../components/image"

import styled from "styled-components"

import {site, colors, frontpage, seo} from "../../theme"

const settings = {
  images: [
    { url: require('../images/bg01.jpg').default, position: 'center' },
    { url: require('../images/bg02.jpg').default, position: 'center' },
    { url: require('../images/bg03.jpg').default, position: 'center' },
  ],
  // Delay.
  delay: 6000,
};

const FrontPage = () => (
  <FPLayout>
    <SEO title={seo.frontpage.title} />
    <Container fluid={true}>
      <Row>
        <Col className="px-0 fpbg">
          <div className="text-center fpjumbo p-0">
            <Image />
          </div>
          <Container>
            <Row>
              <Col>
                <CallToAction>
                  <CallToActionHeading>
                    <span style={{ color: colors.highlight }}>{frontpage.headline}</span>
                    <br /> Start here.
                  </CallToActionHeading>
                  <hr className="my-2" />
                  <p style={{ fontSize: `1.25em` }}>
                  {frontpage.subtitle}
                  </p>
                  <p className="lead">
                    <Link to="/learn/">
                      <Button className="jumbobutton py-3" size="lg">
                        Enter
                      </Button>
                    </Link>
                  </p>
                </CallToAction>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  </FPLayout>
)

export default FrontPage

const CallToAction = styled.div`
  border-radius: 5px;
  background: ${colors.darkbg};
  color: #fff;
  margin-bottom: -160px;
  position: relative;
  top: -180px;
  max-width: 690px;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  text-align: center;
  min-height: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5);
`
const CallToActionHeading = styled.h1`
  font-family: "Jost";
  font-size: 2.3rem;
`
